<template>
  <form @submit.prevent="signIn">
    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      <va-checkbox v-model="keepLoggedIn" class="mb-0" :label="$t('auth.keep_logged_in')"/>
      <router-link class="ml-1 link" :to="{name: 'recover-password'}">{{$t('auth.recover_password')}}</router-link>
    </div>

    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>
import { auth } from '@/firebaseConfig'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
    }
  },
  methods: {
    signIn: function () {
      this.emailErrors = []
      this.passwordErrors = []
      if (this.validateSignIn()) {
        auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then(user => {
            this.$store.commit('setCurrentUser', user.user)
            this.$store.dispatch('fetchUserProfile')
            if (user.user.uid === 'MN4SXU3Kezb9FCpKD6syjCbgoXf2') {
              this.$router.push('/dashboard')
            } else {
              this.emailErrors = ['Oops. This service is for admin accounts only!']
            }
          })
          .catch(() => {
            this.emailErrors = ['Invalid email or password. Try again']
          })
      }
    },

    validateSignIn: function () {
      var result
      var passwordRE = /.{8,}/
      var emailRE = /\w{2,}@\w{2,}\.\w{2,}/

      if (emailRE.test(this.email) && passwordRE.test(this.password)) {
        result = true
      } else {
        if (!emailRE.test(this.email)) {
          this.emailErrors = ['Invalid email address.']
        } else {
          this.passwordErrors = ['Password should be at least 8 characters long.']
        }
        result = false
      }
      return result
    },
  },
}
</script>

<style lang="scss">
</style>
